import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import '../styles/globals.scss';
import Link from 'next/link';
import { PrismicProvider } from '@prismicio/react';
import { PrismicPreview } from '@prismicio/next';
import { repositoryName } from '../prismicio';
import { MobileContext } from '../context/mobileContext';
import { ParallaxProvider } from 'react-scroll-parallax';

export default function App({ Component, pageProps }) {
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-M6TDDB8' });
  }, []);

  if (typeof window !== 'undefined' && !window.structuredClone) {
    function myDeepCopy(value) {
      if (Array.isArray(value)) {
        var count = value.length;
        var arr = new Array(count);
        for (var i = 0; i < count; i++) {
          arr[i] = myDeepCopy(value[i]);
        }

        return arr;
      } else if (typeof value === 'object') {
        var obj = {};
        for (var prop in value) {
          obj[prop] = myDeepCopy(value[prop]);
        }

        return obj;
      } else {
        // Primitive value
        return value;
      }
    }

    window.structuredClone = myDeepCopy;
  }

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    if (typeof window !== 'undefined') {
      setIsMobile(window.innerWidth < 768);
      window.addEventListener('resize', handleResize);
    }
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <PrismicProvider
        internalLinkComponent={({ href, ...props }) => (
          <Link href={href} {...props}></Link>
        )}
      >
        <PrismicPreview repositoryName={repositoryName}>
          <MobileContext.Provider value={isMobile}>
            <ParallaxProvider>
              <Component {...pageProps} />
            </ParallaxProvider>
          </MobileContext.Provider>
        </PrismicPreview>
      </PrismicProvider>
    </>
  );
}
